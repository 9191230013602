.flag-icon-ad {
    background-image: url(../flags/4x3/ad.svg)
}
.flag-icon, .flag-icon-background {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%
}
.flag-icon {
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em
}
.flag-icon:before {
    content: "\00a0"
}
.flag-icon-ad {
    background-image: url(../flags/4x3/ad.svg)
}
.flag-icon-ae {
    background-image: url(../flags/4x3/ae.svg)
}
.flag-icon-af {
    background-image: url(../flags/4x3/af.svg)
}
.flag-icon-ag {
    background-image: url(../flags/4x3/ag.svg)
}
.flag-icon-ai {
    background-image: url(../flags/4x3/ai.svg)
}
.flag-icon-al {
    background-image: url(../flags/4x3/al.svg)
}
.flag-icon-am {
    background-image: url(../flags/4x3/am.svg)
}
.flag-icon-ao {
    background-image: url(../flags/4x3/ao.svg)
}
.flag-icon-aq {
    background-image: url(../flags/4x3/aq.svg)
}
.flag-icon-ar {
    background-image: url(../flags/4x3/ar.svg)
}
.flag-icon-as {
    background-image: url(../flags/4x3/as.svg)
}
.flag-icon-at {
    background-image: url(../flags/4x3/at.svg)
}
.flag-icon-au {
    background-image: url(../flags/4x3/au.svg)
}
.flag-icon-aw {
    background-image: url(../flags/4x3/aw.svg)
}
.flag-icon-ax {
    background-image: url(../flags/4x3/ax.svg)
}
.flag-icon-az {
    background-image: url(../flags/4x3/az.svg)
}
.flag-icon-ba {
    background-image: url(../flags/4x3/ba.svg)
}
.flag-icon-bb {
    background-image: url(../flags/4x3/bb.svg)
}
.flag-icon-bd {
    background-image: url(../flags/4x3/bd.svg)
}
.flag-icon-be {
    background-image: url(../flags/4x3/be.svg)
}
.flag-icon-bf {
    background-image: url(../flags/4x3/bf.svg)
}
.flag-icon-bg {
    background-image: url(../flags/4x3/bg.svg)
}
.flag-icon-bh {
    background-image: url(../flags/4x3/bh.svg)
}
.flag-icon-bi {
    background-image: url(../flags/4x3/bi.svg)
}
.flag-icon-bj {
    background-image: url(../flags/4x3/bj.svg)
}
.flag-icon-bl {
    background-image: url(../flags/4x3/bl.svg)
}
.flag-icon-bm {
    background-image: url(../flags/4x3/bm.svg)
}
.flag-icon-bn {
    background-image: url(../flags/4x3/bn.svg)
}
.flag-icon-bo {
    background-image: url(../flags/4x3/bo.svg)
}
.flag-icon-bq {
    background-image: url(../flags/4x3/bq.svg)
}
.flag-icon-br {
    background-image: url(../flags/4x3/br.svg)
}
.flag-icon-bs {
    background-image: url(../flags/4x3/bs.svg)
}
.flag-icon-bt {
    background-image: url(../flags/4x3/bt.svg)
}
.flag-icon-bv {
    background-image: url(../flags/4x3/bv.svg)
}
.flag-icon-bw {
    background-image: url(../flags/4x3/bw.svg)
}
.flag-icon-by {
    background-image: url(../flags/4x3/by.svg)
}
.flag-icon-bz {
    background-image: url(../flags/4x3/bz.svg)
}
.flag-icon-ca {
    background-image: url(../flags/4x3/ca.svg)
}
.flag-icon-cc {
    background-image: url(../flags/4x3/cc.svg)
}
.flag-icon-cd {
    background-image: url(../flags/4x3/cd.svg)
}
.flag-icon-cf {
    background-image: url(../flags/4x3/cf.svg)
}
.flag-icon-cg {
    background-image: url(../flags/4x3/cg.svg)
}
.flag-icon-ch {
    background-image: url(../flags/4x3/ch.svg)
}
.flag-icon-ci {
    background-image: url(../flags/4x3/ci.svg)
}
.flag-icon-ck {
    background-image: url(../flags/4x3/ck.svg)
}
.flag-icon-cl {
    background-image: url(../flags/4x3/cl.svg)
}
.flag-icon-cm {
    background-image: url(../flags/4x3/cm.svg)
}
.flag-icon-cn {
    background-image: url(../flags/4x3/cn.svg)
}
.flag-icon-co {
    background-image: url(../flags/4x3/co.svg)
}
.flag-icon-cr {
    background-image: url(../flags/4x3/cr.svg)
}
.flag-icon-cr {
    background-image: url(../flags/4x3/cs.svg)
}
.flag-icon-cs {
    background-image: url(../flags/4x3/cs.svg)
}
.flag-icon-cu {
    background-image: url(../flags/4x3/cu.svg)
}
.flag-icon-cv {
    background-image: url(../flags/4x3/cv.svg)
}
.flag-icon-cw {
    background-image: url(../flags/4x3/cw.svg)
}
.flag-icon-cx {
    background-image: url(../flags/4x3/cx.svg)
}
.flag-icon-cy {
    background-image: url(../flags/4x3/cy.svg)
}
.flag-icon-cz {
    background-image: url(../flags/4x3/cz.svg)
}
.flag-icon-de {
    background-image: url(../flags/4x3/de.svg)
}
.flag-icon-dj {
    background-image: url(../flags/4x3/dj.svg)
}
.flag-icon-dk {
    background-image: url(../flags/4x3/dk.svg)
}
.flag-icon-dm {
    background-image: url(../flags/4x3/dm.svg)
}
.flag-icon-do {
    background-image: url(../flags/4x3/do.svg)
}
.flag-icon-dz {
    background-image: url(../flags/4x3/dz.svg)
}
.flag-icon-ec {
    background-image: url(../flags/4x3/ec.svg)
}
.flag-icon-ee {
    background-image: url(../flags/4x3/ee.svg)
}
.flag-icon-eg {
    background-image: url(../flags/4x3/eg.svg)
}
.flag-icon-eh {
    background-image: url(../flags/4x3/eh.svg)
}
.flag-icon-er {
    background-image: url(../flags/4x3/er.svg)
}
.flag-icon-es {
    background-image: url(../flags/4x3/es.svg)
}
.flag-icon-et {
    background-image: url(../flags/4x3/et.svg)
}
.flag-icon-fi {
    background-image: url(../flags/4x3/fi.svg)
}
.flag-icon-fj {
    background-image: url(../flags/4x3/fj.svg)
}
.flag-icon-fk {
    background-image: url(../flags/4x3/fk.svg)
}
.flag-icon-fm {
    background-image: url(../flags/4x3/fm.svg)
}
.flag-icon-fo {
    background-image: url(../flags/4x3/fo.svg)
}
.flag-icon-fr {
    background-image: url(../flags/4x3/fr.svg)
}
.flag-icon-ga {
    background-image: url(../flags/4x3/ga.svg)
}
.flag-icon-gb {
    background-image: url(../flags/4x3/gb.svg)
}
.flag-icon-uk {
    background-image: url(../flags/4x3/gb.svg)
}
.flag-icon-gd {
    background-image: url(../flags/4x3/gd.svg)
}
.flag-icon-ge {
    background-image: url(../flags/4x3/ge.svg)
}
.flag-icon-gf {
    background-image: url(../flags/4x3/gf.svg)
}
.flag-icon-gg {
    background-image: url(../flags/4x3/gg.svg)
}
.flag-icon-gh {
    background-image: url(../flags/4x3/gh.svg)
}
.flag-icon-gi {
    background-image: url(../flags/4x3/gi.svg)
}
.flag-icon-gl {
    background-image: url(../flags/4x3/gl.svg)
}
.flag-icon-gm {
    background-image: url(../flags/4x3/gm.svg)
}
.flag-icon-gn {
    background-image: url(../flags/4x3/gn.svg)
}
.flag-icon-gp {
    background-image: url(../flags/4x3/gp.svg)
}
.flag-icon-gq {
    background-image: url(../flags/4x3/gq.svg)
}
.flag-icon-gr {
    background-image: url(../flags/4x3/gr.svg)
}
.flag-icon-gs {
    background-image: url(../flags/4x3/gs.svg)
}
.flag-icon-gt {
    background-image: url(../flags/4x3/gt.svg)
}
.flag-icon-gu {
    background-image: url(../flags/4x3/gu.svg)
}
.flag-icon-gw {
    background-image: url(../flags/4x3/gw.svg)
}
.flag-icon-gy {
    background-image: url(../flags/4x3/gy.svg)
}
.flag-icon-hk {
    background-image: url(../flags/4x3/hk.svg)
}
.flag-icon-hm {
    background-image: url(../flags/4x3/hm.svg)
}
.flag-icon-hn {
    background-image: url(../flags/4x3/hn.svg)
}
.flag-icon-hr {
    background-image: url(../flags/4x3/hr.svg)
}
.flag-icon-ht {
    background-image: url(../flags/4x3/ht.svg)
}
.flag-icon-hu {
    background-image: url(../flags/4x3/hu.svg)
}
.flag-icon-id {
    background-image: url(../flags/4x3/id.svg)
}
.flag-icon-ie {
    background-image: url(../flags/4x3/ie.svg)
}
.flag-icon-il {
    background-image: url(../flags/4x3/il.svg)
}
.flag-icon-im {
    background-image: url(../flags/4x3/im.svg)
}
.flag-icon-in {
    background-image: url(../flags/4x3/in.svg)
}
.flag-icon-iq {
    background-image: url(../flags/4x3/iq.svg)
}
.flag-icon-ir {
    background-image: url(../flags/4x3/ir.svg)
}
.flag-icon-is {
    background-image: url(../flags/4x3/is.svg)
}
.flag-icon-io {
    background-image: url(../flags/4x3/io.svg)
}
.flag-icon-it {
    background-image: url(../flags/4x3/it.svg)
}
.flag-icon-je {
    background-image: url(../flags/4x3/je.svg)
}
.flag-icon-jm {
    background-image: url(../flags/4x3/jm.svg)
}
.flag-icon-jo {
    background-image: url(../flags/4x3/jo.svg)
}
.flag-icon-jp {
    background-image: url(../flags/4x3/jp.svg)
}
.flag-icon-ke {
    background-image: url(../flags/4x3/ke.svg)
}
.flag-icon-kg {
    background-image: url(../flags/4x3/kg.svg)
}
.flag-icon-kh {
    background-image: url(../flags/4x3/kh.svg)
}
.flag-icon-ki {
    background-image: url(../flags/4x3/ki.svg)
}
.flag-icon-km {
    background-image: url(../flags/4x3/km.svg)
}
.flag-icon-kn {
    background-image: url(../flags/4x3/kn.svg)
}
.flag-icon-kp {
    background-image: url(../flags/4x3/kp.svg)
}
.flag-icon-kr {
    background-image: url(../flags/4x3/kr.svg)
}
.flag-icon-kw {
    background-image: url(../flags/4x3/kw.svg)
}
.flag-icon-ky {
    background-image: url(../flags/4x3/ky.svg)
}
.flag-icon-kz {
    background-image: url(../flags/4x3/kz.svg)
}
.flag-icon-la {
    background-image: url(../flags/4x3/la.svg)
}
.flag-icon-lb {
    background-image: url(../flags/4x3/lb.svg)
}
.flag-icon-lc {
    background-image: url(../flags/4x3/lc.svg)
}
.flag-icon-li {
    background-image: url(../flags/4x3/li.svg)
}
.flag-icon-lk {
    background-image: url(../flags/4x3/lk.svg)
}
.flag-icon-lr {
    background-image: url(../flags/4x3/lr.svg)
}
.flag-icon-ls {
    background-image: url(../flags/4x3/ls.svg)
}
.flag-icon-lt {
    background-image: url(../flags/4x3/lt.svg)
}
.flag-icon-lu {
    background-image: url(../flags/4x3/lu.svg)
}
.flag-icon-lv {
    background-image: url(../flags/4x3/lv.svg)
}
.flag-icon-ly {
    background-image: url(../flags/4x3/ly.svg)
}
.flag-icon-ma {
    background-image: url(../flags/4x3/ma.svg)
}
.flag-icon-mc {
    background-image: url(../flags/4x3/mc.svg)
}
.flag-icon-md {
    background-image: url(../flags/4x3/md.svg)
}
.flag-icon-me {
    background-image: url(../flags/4x3/me.svg)
}
.flag-icon-mf {
    background-image: url(../flags/4x3/mf.svg)
}
.flag-icon-mg {
    background-image: url(../flags/4x3/mg.svg)
}
.flag-icon-mh {
    background-image: url(../flags/4x3/mh.svg)
}
.flag-icon-mk {
    background-image: url(../flags/4x3/mk.svg)
}
.flag-icon-ml {
    background-image: url(../flags/4x3/ml.svg)
}
.flag-icon-mm {
    background-image: url(../flags/4x3/mm.svg)
}
.flag-icon-mn {
    background-image: url(../flags/4x3/mn.svg)
}
.flag-icon-mo {
    background-image: url(../flags/4x3/mo.svg)
}
.flag-icon-mp {
    background-image: url(../flags/4x3/mp.svg)
}
.flag-icon-mq {
    background-image: url(../flags/4x3/mq.svg)
}
.flag-icon-mr {
    background-image: url(../flags/4x3/mr.svg)
}
.flag-icon-ms {
    background-image: url(../flags/4x3/ms.svg)
}
.flag-icon-mt {
    background-image: url(../flags/4x3/mt.svg)
}
.flag-icon-mu {
    background-image: url(../flags/4x3/mu.svg)
}
.flag-icon-mv {
    background-image: url(../flags/4x3/mv.svg)
}
.flag-icon-mw {
    background-image: url(../flags/4x3/mw.svg)
}
.flag-icon-mx {
    background-image: url(../flags/4x3/mx.svg)
}
.flag-icon-my {
    background-image: url(../flags/4x3/my.svg)
}
.flag-icon-mz {
    background-image: url(../flags/4x3/mz.svg)
}
.flag-icon-na {
    background-image: url(../flags/4x3/na.svg)
}
.flag-icon-nc {
    background-image: url(../flags/4x3/nc.svg)
}
.flag-icon-ne {
    background-image: url(../flags/4x3/ne.svg)
}
.flag-icon-nf {
    background-image: url(../flags/4x3/nf.svg)
}
.flag-icon-ng {
    background-image: url(../flags/4x3/ng.svg)
}
.flag-icon-ni {
    background-image: url(../flags/4x3/ni.svg)
}
.flag-icon-nl {
    background-image: url(../flags/4x3/nl.svg)
}
.flag-icon-no {
    background-image: url(../flags/4x3/no.svg)
}
.flag-icon-np {
    background-image: url(../flags/4x3/np.svg)
}
.flag-icon-nr {
    background-image: url(../flags/4x3/nr.svg)
}
.flag-icon-nu {
    background-image: url(../flags/4x3/nu.svg)
}
.flag-icon-nz {
    background-image: url(../flags/4x3/nz.svg)
}
.flag-icon-om {
    background-image: url(../flags/4x3/om.svg)
}
.flag-icon-pa {
    background-image: url(../flags/4x3/pa.svg)
}
.flag-icon-pe {
    background-image: url(../flags/4x3/pe.svg)
}
.flag-icon-pf {
    background-image: url(../flags/4x3/pf.svg)
}
.flag-icon-pg {
    background-image: url(../flags/4x3/pg.svg)
}
.flag-icon-ph {
    background-image: url(../flags/4x3/ph.svg)
}
.flag-icon-pk {
    background-image: url(../flags/4x3/pk.svg)
}
.flag-icon-pl {
    background-image: url(../flags/4x3/pl.svg)
}
.flag-icon-pm {
    background-image: url(../flags/4x3/pm.svg)
}
.flag-icon-pn {
    background-image: url(../flags/4x3/pn.svg)
}
.flag-icon-pr {
    background-image: url(../flags/4x3/pr.svg)
}
.flag-icon-ps {
    background-image: url(../flags/4x3/ps.svg)
}
.flag-icon-pt {
    background-image: url(../flags/4x3/pt.svg)
}
.flag-icon-pw {
    background-image: url(../flags/4x3/pw.svg)
}
.flag-icon-py {
    background-image: url(../flags/4x3/py.svg)
}
.flag-icon-qa {
    background-image: url(../flags/4x3/qa.svg)
}
.flag-icon-re {
    background-image: url(../flags/4x3/re.svg)
}
.flag-icon-ro {
    background-image: url(../flags/4x3/ro.svg)
}
.flag-icon-rs {
    background-image: url(../flags/4x3/rs.svg)
}
.flag-icon-ru {
    background-image: url(../flags/4x3/ru.svg)
}
.flag-icon-rw {
    background-image: url(../flags/4x3/rw.svg)
}
.flag-icon-sa {
    background-image: url(../flags/4x3/sa.svg)
}
.flag-icon-sb {
    background-image: url(../flags/4x3/sb.svg)
}
.flag-icon-sc {
    background-image: url(../flags/4x3/sc.svg)
}
.flag-icon-sd {
    background-image: url(../flags/4x3/sd.svg)
}
.flag-icon-se {
    background-image: url(../flags/4x3/se.svg)
}
.flag-icon-sg {
    background-image: url(../flags/4x3/sg.svg)
}
.flag-icon-sh {
    background-image: url(../flags/4x3/sh.svg)
}
.flag-icon-si {
    background-image: url(../flags/4x3/si.svg)
}
.flag-icon-sj {
    background-image: url(../flags/4x3/sj.svg)
}
.flag-icon-sk {
    background-image: url(../flags/4x3/sk.svg)
}
.flag-icon-sl {
    background-image: url(../flags/4x3/sl.svg)
}
.flag-icon-sm {
    background-image: url(../flags/4x3/sm.svg)
}
.flag-icon-sn {
    background-image: url(../flags/4x3/sn.svg)
}
.flag-icon-so {
    background-image: url(../flags/4x3/so.svg)
}
.flag-icon-sr {
    background-image: url(../flags/4x3/sr.svg)
}
.flag-icon-ss {
    background-image: url(../flags/4x3/ss.svg)
}
.flag-icon-st {
    background-image: url(../flags/4x3/st.svg)
}
.flag-icon-sv {
    background-image: url(../flags/4x3/sv.svg)
}
.flag-icon-sx {
    background-image: url(../flags/4x3/sx.svg)
}
.flag-icon-sy {
    background-image: url(../flags/4x3/sy.svg)
}
.flag-icon-sz {
    background-image: url(../flags/4x3/sz.svg)
}
.flag-icon-tc {
    background-image: url(../flags/4x3/tc.svg)
}
.flag-icon-td {
    background-image: url(../flags/4x3/td.svg)
}
.flag-icon-tf {
    background-image: url(../flags/4x3/tf.svg)
}
.flag-icon-tg {
    background-image: url(../flags/4x3/tg.svg)
}
.flag-icon-th {
    background-image: url(../flags/4x3/th.svg)
}
.flag-icon-tj {
    background-image: url(../flags/4x3/tj.svg)
}
.flag-icon-tk {
    background-image: url(../flags/4x3/tk.svg)
}
.flag-icon-tl {
    background-image: url(../flags/4x3/tl.svg)
}
.flag-icon-tm {
    background-image: url(../flags/4x3/tm.svg)
}
.flag-icon-tn {
    background-image: url(../flags/4x3/tn.svg)
}
.flag-icon-to {
    background-image: url(../flags/4x3/to.svg)
}
.flag-icon-tr {
    background-image: url(../flags/4x3/tr.svg)
}
.flag-icon-tt {
    background-image: url(../flags/4x3/tt.svg)
}
.flag-icon-tv {
    background-image: url(../flags/4x3/tv.svg)
}
.flag-icon-tw {
    background-image: url(../flags/4x3/tw.svg)
}
.flag-icon-tz {
    background-image: url(../flags/4x3/tz.svg)
}
.flag-icon-ua {
    background-image: url(../flags/4x3/ua.svg)
}
.flag-icon-ug {
    background-image: url(../flags/4x3/ug.svg)
}
.flag-icon-um {
    background-image: url(../flags/4x3/um.svg)
}
.flag-icon-en {
    background-image: url(../flags/4x3/us.svg)
}
.flag-icon-us {
    background-image: url(../flags/4x3/us.svg)
}
.flag-icon-us {
    background-image: url(../flags/4x3/us.svg)
}
.flag-icon-uy {
    background-image: url(../flags/4x3/uy.svg)
}
.flag-icon-uz {
    background-image: url(../flags/4x3/uz.svg)
}
.flag-icon-va {
    background-image: url(../flags/4x3/va.svg)
}
.flag-icon-vc {
    background-image: url(../flags/4x3/vc.svg)
}
.flag-icon-vg {
    background-image: url(../flags/4x3/vg.svg)
}
.flag-icon-ve {
    background-image: url(../flags/4x3/ve.svg)
}
.flag-icon-vi {
    background-image: url(../flags/4x3/vi.svg)
}
.flag-icon-vn {
    background-image: url(../flags/4x3/vn.svg)
}
.flag-icon-vu {
    background-image: url(../flags/4x3/vu.svg)
}
.flag-icon-wf {
    background-image: url(../flags/4x3/wf.svg)
}
.flag-icon-ws {
    background-image: url(../flags/4x3/ws.svg)
}
.flag-icon-ye {
    background-image: url(../flags/4x3/ye.svg)
}
.flag-icon-yt {
    background-image: url(../flags/4x3/yt.svg)
}
.flag-icon-za {
    background-image: url(../flags/4x3/za.svg)
}
.flag-icon-zm {
    background-image: url(../flags/4x3/zm.svg)
}
.flag-icon-zw {
    background-image: url(../flags/4x3/zw.svg)
}
.flag-icon-eu {
    background-image: url(../flags/4x3/eu.svg)
}
.flag-icon-uk-eng {
    background-image: url(../flags/4x3/gb-eng.svg)
}
.flag-icon-gb-sct {
    background-image: url(../flags/4x3/gb-sct.svg)
}
.flag-icon-uk-wls {
    background-image: url(../flags/4x3/gb-wls.svg)
}
